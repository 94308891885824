<template>
  <div>
    <Loading :loading="true" :opacity="1.0" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Loading from "../../components/app/Loading";

export default {
  components: { Loading },

  mounted() {
    this.axios
      .post("https://api.dd.meutem.com.br/v1/api-auth/login", {
        companyId: "1286918",
        apiKey: "P21A56GbvBwoAkjNK",
      })
      .then((response) => {
        const tokenzeus = response.data.data.token;
        let usertoken = this.userToken;

        if (usertoken.includes(' ')) {
          usertoken = usertoken.replace(/ /g, '+');
        }

        this.axios
          .post("https://api.dd.meutem.com.br/v1/hello/check_user_token", {
            user_token: usertoken,
          })
          .then((response) => {
            
            const respData = response.data?.[0];

            const magicLinkPayload = {
              clientCpf: respData.cpf,
              tokenzeus: tokenzeus,
              onixCode: respData.onix,
            };

            this.axios
              .post(
                "https://api.dd.meutem.com.br/v1/hello/generate_magic_link",                    
                magicLinkPayload
              )
              .then((magicLinkResponse) => {
                console.log('magicLinkResponse', magicLinkResponse);

                const magicLinkUrl =
                  magicLinkResponse.data?.data?.url || null;

                if (magicLinkUrl) {
                  console.log("Redirecionando para:", magicLinkUrl);
                  
                  window.location.href = magicLinkUrl;
                } else {
                  throw new Error("URL do Magic Link não encontrada.");
                }
              })
              .catch((err) => {
                console.error("Erro ao gerar Magic Link:", err);
                this.handleLoginFailed(err);
              });
          })
          .catch(this.handleLoginFailed);
      })
      .catch(this.handleLoginFailed);
  },

  methods: {
    handleLoginFailed(err) {
      console.error(err);
      this.$store.dispatch("account/resetUserToken");
      this.$router.push({ name: "login.error" });
    },
  },

  computed: {
    ...mapGetters("account", ["userToken", "loggedUser"]),
  },
};
</script>
